<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              선수금 종류
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                  v-model="searchConditions.depositKinds"
                  cssClass="lookup-condition-multiselect"
                  :dataSource="searchOptions.depositKinds"
                  :fields="commonCodeFields"
                  placeHolder="전체"
                  width="180"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              입금자명
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.memberName"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        value="false"
                        v-model="searchConditions.isUseDate"
                      />
                      <i/>
                      <div class="label" style="padding-right: 6px; color: #000;">사용일자</div>
                    </label>
                  </li>
                </ul>
              </li>
              <li class="item dateRange">
                <input-date-range
                  type="lookup-condition"
                  v-model="dateRange"
                  :disabled="!searchConditions.isUseDate"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              사용자명
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.useName"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              사용구분
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                  v-model="searchConditions.depositUseDivs"
                  cssClass="lookup-condition-multiselect"
                  :dataSource="searchOptions.depositUseDivs"
                  :fields="commonCodeFields"
                  placeHolder="전체"
                  width="180"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        value="true"
                        v-model="isNotGroupAggregate"
                        @change="onIsNotGroupAggregateChanged"
                      />
                      <i></i>
                      <div class="label">소계 안보이기</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              :ignore="isPopupOpened"
              @click.native="searchButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">선수금사용현황</div>
              <div class="header-caption">[{{depositUseStatusLength}}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      buttonDiv="FILE"
                      :ignore="isPopupOpened"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="depositUseStatusGrid"
                v-bind="depositUseStatusGridOptions"
                :dataSource="depositUseStatusList"
                :aggregates="depositUseStatusGridAggregates"
                @queryCellInfo="depositUseStatusGridQueryCellInfo"
                @actionComplete="depositUseStatusGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import ErpButton from "@/components/button/ErpButton.vue";
import InputText from "@/components/common/text/InputText";
import InputDateRange from '@/components/common/datetime/InputDateRange';
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Aggregate, ExcelExport, Filter, ForeignKey, Page, Group, Resize} from '@syncfusion/ej2-vue-grids';
import {commonCodesGetCommonCode, commonCodesGetSortNo} from "@/utils/commonCodes";
import {getTodayDate, getFormattedDate} from "@/utils/date";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { orderBy as _orderBy } from "lodash";

export default {
  name: "DepositUseStatus",
  components: {
    InputText,
    InputDateRange,
    ErpButton,
    EjsGridWrapper,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      isNotGroupAggregate: true,
      commonCodeFields: {text: "comName", value: "comCode"},
      dateRange: {
        from: null,
        to: null,
      },
      searchConditions: {
        depositKinds: [],
        depositUseDivs: [],
        memberName: null,
        useName: null,
        isUseDate: false,
      },
      searchOptions: {
        depositKinds: commonCodesGetCommonCode("DEPOSIT_KIND", true),
        depositUseDivs: commonCodesGetCommonCode("DEPOSIT_USE_DIV", true),
      },
      depositUseStatusList: [],
      depositUseStatusLength: 0,
      depositUseStatusGridOptions: {
        provides: [
          Aggregate,
          Filter,
          Resize,
          Page,
          Group,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        },
        groupSettings: {
          columns: ["useDateByGroup"],
          showDropArea: false,
        },
        allowExcelExport: true,
        allowGrouping: true,
        allowPaging: true,
        pageSettings: {pageSize: 50},
        columns: [
          {
            field: "useId",
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "useDateByGroup",
            visible: false,
          },
          {
            field: "useDate",
            headerText: "사용일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "memberName",
            headerText: "사용자명",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "startCourse",
            headerText: "코스",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "COURSE_CODE",
          },
          {
            field: "startTime",
            headerText: "시간",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "useAmt",
            headerText: "사용금액",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "depositUseDiv",
            headerText: "사용구분",
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "DEPOSIT_USE_DIV",
          },
          {
            field: "insertName",
            headerText: "입력자",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "insertDt",
            headerText: "입력일시",
            type: "string",
            minWidth: 16,
            width: 150,
            textAlign: "center",
          },
          {
            field: "incomDate",
            headerText: "입금일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "depositKind",
            headerText: "종류",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "DEPOSIT_KIND",
          },
          {
            field: "preCardNo",
            headerText: "선불카드 번호",
            type: "string",
            allowEditing: true,
            textAlign: "Left",
            width: 110
          },
          {
            field: "depositMemberName",
            headerText: "입금자명",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
          },
        ],
      },
    };
  },
  created() {
    this.dateRange = {
      from: getFormattedDate(getTodayDate(), "YYYY-MM-01"),
      to: getTodayDate(),
    };
  },
  computed: {
    isPopupOpened() {
      return false;
    },
    depositUseStatusGridAggregates() {
      return [
        {
          columns: this.isNotGroupAggregate
            ? []
            : [
              {
                field: "startTime",
                aggregationType: "GroupCaption",
                customAggregate: "일자별 소계",
              },
              {
                field: "useAmt",
                aggregationType: "GroupSum",
              }
            ]
        },
        {
          columns: [
            {
              field: "startTime",
              aggregationType: "TotalCaption",
              customAggregate: "총계",
            },
            {
              field: "useAmt",
              aggregationType: "TotalSum",
            }
          ]
        }
      ];
    }
  },
  methods: {
    async searchButtonClicked() {
      const data = await GolfErpAPI.fetchDepositUseStatus({
        depositKinds: this.searchConditions.depositKinds,
        memberName: this.searchConditions.memberName,
        dateFrom: this.searchConditions.isUseDate ? this.dateRange.from : null,
        dateTo: this.searchConditions.isUseDate ? this.dateRange.to : null,
        depositUseDivs: this.searchConditions.depositUseDivs,
        useName: this.searchConditions.useName,
      });

      this.depositUseStatusList =
        // Grid에서 그룹화하면 데이터 orderBy와 상관없이 Grid 내에서 자동 정렬됨.
        _orderBy(
          data.map(depositUse => ({
            ...depositUse,
            useDateByGroup: depositUse.useDate,
            depositMemberName: depositUse?.deposit?.memberName,
            incomDate: depositUse?.deposit?.incomDate,
            depositKind: depositUse?.deposit?.depositKind,
            courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", depositUse.startCourse),
          }))
          , ["useDate", "startTime", "courseCodeSortNo"]);
    },
    excel() {
      this.$refs.depositUseStatusGrid.excelExport();
    },
    depositUseStatusGridQueryCellInfo() {},
    depositUseStatusGridActionComplete(args) {
      const {
        requestType
      } = args;

      if (["refresh", "filtering"].includes(requestType)) {
        this.depositUseStatusLength = args?.rows?.length || 0;
      }
    },
    onIsNotGroupAggregateChanged() {
      this.searchButtonClicked();
    }
  },
};
</script>
